export const FirebaseConfig = {
	"projectId": "sengo-aeefe",
	"appId": "1:445347752268:web:a3c10c150afe1dd59807e9",
	"databaseURL": "https://sengo-aeefe-default-rtdb.firebaseio.com",
	"storageBucket": "sengo-aeefe.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBYj3mgcOuWMrzh_xKxnUennfLXc74xa44",
	"authDomain": "sengo-aeefe.firebaseapp.com",
	"messagingSenderId": "445347752268",
	"measurementId": "G-X7JQ1LY97Z"
};